<!-- =========================================================================================
    File Name: ItemListView.vue
    Description: Item Component - List VIew
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->
<template>
  <!-- class="p-6 bg-white shadow-md rounded-lg flex lg:flex-row lg:justify-between lg:items-center lg:space-x-6" -->
  <div class=" p-3 bg-white rounded-lg tile-border items-end"
  :class="{ 'is-hidden': isHidden }"
  >
    <!-- delete -->
    <!-- <div class="text-right lg:text-left"><slot name="remove"/></div> -->

    <div class="hidden md:flex w-full justify-between">
      <!-- product -->
      <div class="w-1/3">
        <div class=" w-full flex">
          <img
            @click="navigate_to_detail_view"
            class="block w-6/12 lg:w-3/12 lg:h-auto mr-6 object-contain mb-4 lg:mb-0 h-24"
            :src="itemImgSrc(item)"
          />
          
          <div class="flex items-center justify-start w-full">
            <div class="text-lg opacity-75 hidden">{{ item.productType }}</div>
            <div
              class="font-semibold cursor-pointer title-min-height flex items-center"
              style="text-wrap: balance;"
            >
              <span 
                v-if="!item.patient && item.product.type !== 'bundled-items' && item.product.type !== 'bundle'"
                @click="navigate_to_detail_view"
              >
                {{ productName(item) }}
              </span>
              <vx-tooltip
                :text="item.patient ? 'Scripting Item' : item.product.type === 'bundled-items' && item.bundleItems.length > 0 ? 'Bundled Items' : item.product.type === 'bundle' && item.bundleItems.length > 0 ? 'Bundled Products' : ''"
                style="display: flex"
                v-if="item.patient || item.product.type === 'bundled-items' || item.product.type === 'bundle'"
              >
                <p>
                  <span @click="navigate_to_detail_view">
                    {{ productName(item) }}
                  </span>
                  <span class=" inline-block">
                    <span class=" flex items-baseline">
                      <vs-icon v-if="item.patient" icon="info_outline" size="18px" @click="openDetails"></vs-icon>
                      <vs-icon v-if="!item.patient && (item.product.type === 'bundled-items' || item.product.type === 'bundle') && item.bundleItems.length > 0" icon="info_outline" size="18px" @click="openBudledItems"></vs-icon>
                    </span>
                  </span>
                </p>  
              </vx-tooltip>
            </div>
          </div>
        </div>
      </div>
      <div class="w-8/12 flex justify-end">
        <!-- price -->
        <div class=" w-1/4 my-auto text-center">
          <div v-if="isDiscounted() || hasPromo()">
            <span class="text-base line-through"> ${{ getActualPrice() | formatDecimals }}
            </span>
          </div>
          <div
            v-if="isDiscounted() || hasPromo()"
            class="font-semibold text-primary"
          >
            <span v-if="!isLoggedInSuperAdmin">
              ${{ getDiscountedPrice() | formatDecimals }}
              <!-- <span class="line-through">${{ getActualPrice() | formatDecimals }}</span> ${{ getDiscountedPrice() | formatDecimals }} -->
            </span>
            <span
              v-else
              class="flex flex-col"
            >
              <span class="flex items-center mb-2">
                <span class="mr-1">$</span>
                <vs-input
                  type="number"
                  :is-disabled="disableInput"
                  :disabled="disableInput"
                  v-model.number="item.superAdminDiscountPrice"
                  @change="changePrice('superAdminDiscountPrice')()"
                  @focusout = "item.superAdminDiscountPrice = item.superAdminDiscountPrice || 0"
                />
              </span>
              <span class="line-through mb-2">
                Original Price: ${{ getActualPrice(true) | formatDecimals }}
              </span>
              <span>
                Discounted Price: ${{ getDiscountedPrice() | formatDecimals }}
              </span>
            </span>
          </div>
          <div
            v-else
            class=" mt-1 font-semibold text-base text-primary"
          >
              <span>$</span>
              <span v-if="!isLoggedInSuperAdmin">{{ item.price | formatDecimals }}</span>
              <vs-input
                v-else
                type="number"
                :is-disabled="disableInput"
                :disabled="disableInput"
                v-model.number="item.superAdminPrice"
                @change="changePrice('superAdminPrice')()"
                @focusout = "item.superAdminPrice = item.superAdminPrice || 0"
              />
          
          <!-- 
            <patient-details :patient="item.patient" v-if="item.patient" /> 
            <bundle-details :bundle="item.bundleItems" v-if="item.bundleItems" />
            <bundled-items-details :items="item.bundledItems" v-if="item.bundledItems && item.bundledItems.length"/>
          -->
          </div>
        </div>
    
        <div  class=" w-1/4 my-auto text-center">
          <div v-if="isDiscounted() || hasPromo()">
            <span class="text-base line-through text-center"> ${{ getActualPrice() * item.quantity | formatDecimals }}
            </span>
          </div>
          <p class="font-semibold text-base">${{ getSubTotal() | formatDecimals }}</p>
        </div>
        <div  class=" w-1/4 my-auto flex justify-center">
          <!-- <p class="font-semibold text-lg">${{ getSubTotal() | formatDecimals }}</p> -->
          <div v-if="isDiscounted() || hasPromo()" class="place-self-center text-center savings p-2 rounded-lg w-24">
            <div class=" text-base ">
              You save
            </div>
            <div class="font-semibold text-base text-primary">
              ${{getTotalSavings() | formatDecimals }}!
            </div>
          </div>
          <div v-else class=" w-32">
    
          </div>
        </div>
        <!-- action -->
        <div class="flex items-center justify-center  w-1/4 my-auto text-center"
          v-if="!isEditToggled"
          :style="isLoggedInSuperAdmin && 'margin-top: -40px'"
        >
          <slot name="action-buttons" />
        </div>
      </div>
    </div>

    <!-- mobile view -->
    <div class=" block md:hidden">

      <div class="flex justify-between w-full mb-5 items-center">
        <div class=" w-1/2">
          <img
            @click="navigate_to_detail_view"
            class="block w-6/12 lg:w-2/12 mr-6 object-cover mb-4 lg:mb-0 h-24"
            :src="itemImgSrc(item)"
          />
        </div>
        <div class=" w-1/2">
          <div class="flex items-center justify-between my-auto text-center"
            :style="isLoggedInSuperAdmin && 'margin-top: -40px'"
          >
            <slot name="action-buttons" />
          </div>
        </div>
      </div>

      <div class=" mb-5">
        <div class="flex justify-start items-center w-full">
          <div class="text-lg opacity-75 hidden">{{ item.productType }}</div>
          <div
            class="font-semibold cursor-pointer title-min-height"
            style="text-wrap: balance;"
            @click="navigate_to_detail_view"
          >
            {{ productName(item) }}
          </div>
          <template v-if="item.patient || item.product.type === 'bundled-items' || item.product.type === 'bundle'">
            <vs-icon v-if="item.patient" class="pl-1" icon="info_outline" size="18px" @click="openDetails"></vs-icon>
            <vs-icon v-if="!item.patient && (item.product.type === 'bundled-items' || item.product.type === 'bundle')" class="pl-1" icon="info_outline" size="18px" @click="openBudledItems"></vs-icon>
          </template>
        </div>
      </div>

      <div class=" flex justify-between">

        <div class="">
          <div v-if="getListPrice() > 0">
            <span class="text-base">Price</span>
          </div>
          <div
            v-if="isDiscounted()"
            class="mt-2 font-semibold my-3"
          >
            <span v-if="!isLoggedInSuperAdmin">
              ${{ getDiscountedPrice() | formatDecimals }}
            </span>
            <span
              v-else
              class="flex flex-col"
            >
              <span class="flex items-center mb-2">
                <span class="mr-1">$</span>
                <vs-input
                  type="number"
                  :is-disabled="disableInput"
                  :disabled="disableInput"
                  v-model.number="item.superAdminDiscountPrice"
                  @change="changePrice('superAdminDiscountPrice')()"
                  @focusout = "item.superAdminDiscountPrice = item.superAdminDiscountPrice || 0"
                />
              </span>
              <span class="line-through mb-2">
                Original Price: ${{ getActualPrice(true) | formatDecimals }}
              </span>
              <span>
                Discounted Price: ${{ getDiscountedPrice() | formatDecimals }}
              </span>
            </span>
          </div>
          <div v-else>
            Price
            <p class="font-semibold text-base text-primary">
            <span>$</span>
            <span v-if="!isLoggedInSuperAdmin">{{ item.price | formatDecimals }}</span>
            <vs-input
              v-else
              type="number"
              :is-disabled="disableInput"
              :disabled="disableInput"
              v-model.number="item.superAdminPrice"
              @change="changePrice('superAdminPrice')()"
              @focusout = "item.superAdminPrice = item.superAdminPrice || 0"
            />
            </p>
            <!-- 
              <patient-details :patient="item.patient" @handleUpdatePatient="handleUpdatePatient" v-if="item.patient" :isEditToggled="isEditToggled" @changeEditToggle="isEditToggled = $event" /> 
              <bundle-details :bundle="item.bundleItems" v-if="item.bundleItems" />
              <bundled-items-details :items="item.bundledItems" v-if="item.bundledItems && item.bundledItems.length"/>
            -->
          </div>
        </div>

        <div>
          Total
          <p class="font-semibold text-base">${{ getSubTotal() | formatDecimals }}</p>
        </div>

        <div>
          <div v-if="getTotalSavings() / 100 > 0" class="place-self-center text-center savings p-3 rounded-lg w-24">
            <div class=" text-base ">
              You save
            </div>
            <div class="font-semibold text-base text-primary">
              ${{getTotalSavings()}}!
            </div>
          </div>
          <div v-else class=" w-32">
    
          </div>
        </div>
      </div>
    </div>

    <vs-popup class="holamundo" :active.sync="scriptingPopup">
      <div class=" font-semibold text-base text-primary p-3 overflow-auto" style=" max-height: 800px;">
        <patient-details :patient="item.patient" @handleUpdatePatient="handleUpdatePatient" v-if="item.patient" :isEditToggled="isEditToggled" @changeEditToggle="isEditToggled = $event" />
      </div>
    </vs-popup>
    <vs-popup class="holamundo" :active.sync="bundledItemsPopup">
      <div class=" font-semibold text-base text-primary p-5">
        <bundle-details :bundle="item.bundleItems" v-if="item.product.type === 'bundle' && item.bundleItems" />
        <bundled-items-details :items="item.bundleItems" v-if="item.product.type === 'bundled-items' && item.bundleItems && item.bundleItems.length"/>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import PatientDetails from "./PatientDetails";
import BundleDetails from "./BundleDetails.vue";
import BundledItemsDetails from "./BundledItemsDetails";
import { debounce } from "lodash";
import { mapActions } from "vuex";

export default {
  inject: ["getRoleRouteName"],
  components: {
    PatientDetails,
    BundleDetails,
    BundledItemsDetails,
  },
  data() {
    return {
      quantity: 0,
      userTypeAdmin: undefined,
      disableInput: false,
      isEditToggled: false,
      scriptingPopup: false,
      bundledItemsPopup: false
    };
  },
  props: {
    isHidden: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      required: true,
    },
    discounts: {
      type: Array,
      required: true
    },
    hasClinicDiscount: {
      type: Function,
      required: true
    },
    isDoctorStore: {
      type: Boolean,
      required: false
    },
    promo: {
      type: Array,
      required: false,
      default: () => []
    },
  },
  computed: {
    isInCart() {
      return (itemId) => this.$store.getters["eCommerce/isInCart"](itemId);
    },
    isInWishList() {
      return (itemId) => this.$store.getters["eCommerce/isInWishList"](itemId);
    },
    isLoggedInSuperAdmin() {
      return this.$store.state.AppActiveUser.userType === "superAdmin"
    }
  },
  methods: {
    ...mapActions("clinic", ["updateScriptProductPatientDetails"]),
    productName(item){
      if(this.isDoctorStore ===null){
        return item.product.name
      }
      else {
        if(this.isDoctorStore){
          return item.product.productName
        }
        else {
          return item.name
        }
      }
    },
    async handleUpdatePatient(patient){
      try {
        const payload = {
          id: this.item._id,
          payload: patient
        }
        const res = await this.updateScriptProductPatientDetails(payload);
        if (res.status == 200) {
          this.$vs.notify({
            title: "Success",
            text: "Patient details updated successfully.",
            color: "success",
          });
          this.isEditToggled = false;
          this.$emit("fetchCartItems");
        }
      } catch (error) {
        console.error("ERR: ", error);
      }
    },
    changePrice(key) {
      return () => {
        // disable input first
        this.disableInput = true

        return debounce(() => {
          const item = this.item;
          if (key === 'superAdminPrice') item.adjustedPrice = item[key] * 100
          if (key === 'superAdminDiscountPrice' && this.isDiscounted()) item.adjustedDiscountPrice = item[key] * 100

          // remove keys superAdminDiscountPrice if not discounted
          if (!this.isDiscounted()) {
            delete item.superAdminDiscountPrice
            delete item.adjustedDiscountPrice
            delete item.superAdminDiscountPriceFrom
          }

          // update cart item
          this.$store.dispatch("eCommerce/updateItemQuantity", {
            item,
          });

          setTimeout(() => {
            // fetch cart items
            this.$emit('fetchCartItems')

            // enable input
            this.disableInput = false
          }, 500)
        }, 500)()
      }
    },
    isDiscounted() {
      return this.discounts.some(discount=>discount.productIds.some(id=>id===this.item.productStoreId));
    },
    hasPromo() {
      return this.promo.some(promo => promo.productIds.some(id=> id === this.item.productStoreId));
    },
    promoRule() {
      const matchedPromo = this.promo.filter(promo=> promo.productIds.some(id=> id === this.item.productStoreId))

      if (matchedPromo.length) return matchedPromo[0].rule

      return undefined
    },
    getActualPrice(isOriginalPrice = false) {
      // use price as default
      const role = localStorage.getItem(`${process.env.VUE_APP_PROCESS_TOKEN}Role`);
      let price = this.item.originalPrice || this.item.price || 0;

      // has discount use listPrice as price
      const rule = this.getRule();

      if(rule && rule.discount_price) {
        switch(rule.discount_price) {
          case 'List Price':
            price = this.item.listPrice;
            break;
          case 'Nurse Price':
            price = this.item.originalPrice || this.item.price;
            break;
          case 'Doctor Price':
            price = this.item.doctorPrice;
            break;
          default:
            price = this.item.rrp;
            break;
        }
      }

      // if superAdminDiscountPrice exists, use superAdminDiscountPrice
      if (this.item.hasOwnProperty('superAdminDiscountPrice') && !isOriginalPrice && role === 'admin') {
        price = this.item.superAdminDiscountPrice * 100
      }

      return price
    },
    getRule() {
      const matchedDiscount = this.discounts.filter(discount=>discount.productIds.some(id=>id===this.item.productStoreId))

      if (matchedDiscount.length) return matchedDiscount[0].rule

      return undefined
    },
    getDiscountedPrice() {
      const price = this.getActualPrice();
      const rule = this.getRule();
      const promoRule = this.promoRule();

      if (rule) {
        if(rule.discount_price) {
          if(rule.discount_price) {
            return rule.discount_type === 'percentage' ? price - (price * (rule.discount_value/100)) : price - (rule.itemDiscount * 100);
          } 
        } else if(rule.discount_type === 'percentage') {
          return price - (price * (rule.discount_value/100));
        }
        return price - (rule.itemDiscount * 100);
      }

      if (promoRule) return promoRule.fresh_price

      return price
    },
    getSubTotal() {
      let price = this.item.price

      if (this.item.hasOwnProperty('superAdminPrice') && this.isLoggedInSuperAdmin) price = this.item.superAdminPrice * 100

      if (this.isDiscounted() || this.hasPromo()) {
        return this.getDiscountedPrice() * this.item.quantity;
      }
      return price * this.item.quantity;
    },
    getListPrice() {
      return this.item.listPrice;
    },
    getTotalSavings(){
      if(this.isDiscounted() || this.hasPromo()){
        return ((this.getActualPrice() - this.getDiscountedPrice()) * this.item.quantity)
        // return ((this.item.listPrice * this.item.quantity)/100) - ((this.item.price * this.item.quantity)/100)
      }
      return 0
    },
    openDetails(){
      this.scriptingPopup = true;
    },
    openBudledItems(){
      this.bundledItemsPopup = true;
    },
    toggleItemInWishList(item) {
      this.$store.dispatch("eCommerce/toggleItemInWishList", item);
    },
    selectedChangeQuantity(quantity) {
      this.item.selectedQuantity = quantity;
    },
    additemInCart(item) {
      this.$store.dispatch("eCommerce/additemInCart", item);
    },
    cartButtonClicked(item) {
      this.isInCart(item.objectID)
        ? this.$router.push("/apps/eCommerce/checkout").catch(() => {})
        : this.additemInCart(item);
    },
    navigate_to_detail_view() {

      let routername;

      if(this.userTypeAdmin && this.userTypeAdmin === "superAdmin") {
        routername = "SuperAdminProductDetail"

      } else {
        routername = this.getRoleRouteName("ProductDetail")
      }

      this.$router
        .push({
          name: routername,
          params: {
            id: this.item.productStoreId,
          },
        })
        .catch(() => {});

    },
    itemImgSrc(item) {
      return item.images.length ? item.images[0].image : undefined
    }
  },
  created() {
    this.userTypeAdmin = this.$store.state.AppActiveUser.userType
  },
  watch: {
    discounts: function() {
      if (
        !this.item.hasOwnProperty('superAdminDiscountPriceFrom') &&
        this.isDiscounted()
      ) {
        // use price as default
        let price = this.item.price

        const rule = this.discounts.filter(discount=>discount.productIds.some(id=>id===this.item.productStoreId))[0].rule;

        // has bulk discount rule & clinic discount, bulk discount < clinic discount
        if (rule && this.hasClinicDiscount(this.item.productStoreId) && this.item.originalPrice) price = this.item.originalPrice

        // has discount use listPrice as price
        if (rule.discount_price && rule.discount_price === 'List Price') price = this.item.listPrice;

        this.item.superAdminDiscountPrice = price / 100
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.list-view-item {
  .item-name,
  .item-description {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .item-name {
    -webkit-line-clamp: 2;
  }

  .item-description {
    -webkit-line-clamp: 5;
  }

  .grid-view-img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    transition: 0.35s;
  }

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);

    .grid-view-img {
      opacity: 0.9;
    }
  }
}
.savings{
  background: #EBF7F4;
}

.tile-border{
    border: 1px solid #e5e7eb!important;
}

.is-hidden {
  opacity: 0.3;
  pointer-events: none;
}

.badge {
    display: inline-block; /* Use inline-block to fit content */
    width: 100px; /* Set your desired width here */
    font-size: 0.875em;
    color: #333; /* Text color */
    background-color: #d3d3d3; /* Gray background color */
    border-radius: 12px; /* Rounded corners */
    border: 1px solid #b0b0b0; /* Optional: border color */
    text-align: center;
    box-sizing: content-box; /* Ensure padding doesn't affect width calculation */
    font-weight: bold;
}
</style>
